.App {
  text-align: center;
}

:root {
  --blue: #44aaff;
  --red: #ee0000;
  --green: #00ee00;
  --black: #000;
}
